import React, { useState, useEffect } from "react"
import ScrollLock, { TouchScrollable } from "react-scrolllock"
import "./Modal.css"

const Modal = props => {
  const [closing, setClosing] = useState(false)

  useEffect(() => {
    if (closing) {
      window.setTimeout(() => {
        props.close()
      }, 500)
    }
  }, [props, closing])

  const closeModal = e => {
    if (e.target.className === "Background" || e.target.className === "Close") {
      setClosing(true)
    }
  }

  return (
    <ScrollLock>
      <div
        className="Background"
        id={closing ? "BackgroundExit" : null}
        onClick={closeModal}
      >
        <TouchScrollable>
          <div
            className="Modal"
            id={closing ? "ModalExit" : null}
            style={{ ...props.style }}
            onClick={null}
          >
            <img
              src="./icons/close.png"
              className="Close"
              onClick={closeModal}
              alt="close"
            />
            {props.children}
          </div>
        </TouchScrollable>
      </div>
    </ScrollLock>
  )
}

export default Modal
