import React, { useEffect } from "react"
import ReactHtmlParser from "react-html-parser"
import { hasMultipleLocations } from "../helpers"
import CalltoActionButton from "../CalltoActionButton/CalltoActionButton"
import AnalyticsContextHOC from "../AnalyticsContext/AnalyticsContextHOC.js"
import "./Splash.css"

const Splash = props => {
  useEffect(() => {
    props.analytics.pageview("splash")
  }, [])

  const handleClick = () => {
    props.analytics.send(
      props.rest.name,
      "Order Online", 
      "Splash Page")
  }

  const handleOrderClick = () => {
    handleClick()
    if (hasMultipleLocations(props.rest)) {
      props.scrollToSection()
    }
  }

  const renderHeader = () => {
    if (props.rest.splash.logo) {
      let imgstyle = null
      if (props.rest.splash.logo_width && window.innerWidth > 800) {
        imgstyle = { width: props.rest.splash.logo_width }
      }
      return (
        <img
          src={props.rest.splash.logo}
          className="SplashLogo"
          style={imgstyle}
          alt="rest_logo"
        />
      )
    } else {
      return <div className="SplashHeader">{props.rest.splash.header}</div>
    }
  }

  if (props.rest.splash.orientation === "horizontal") {
    return (
      <div className="SplashContainerHorizontal">
        <div className="SplashSubHeaderHorizontal">
          {ReactHtmlParser(props.rest.splash.subheader)}

          <CalltoActionButton
            onClick={handleClick}
            href={props.rest.ordering_url}
            params={{ ...props.rest.utms, order_type: "pickup" }}
            className="SplashButton"
            style={{ background: props.rest.hex_color_secondary }}
            title="ORDER PICKUP"
          />

          <CalltoActionButton
            onClick={handleClick}
            href={props.rest.ordering_url}
            params={{ ...props.rest.utms, order_type: "delivery" }}
            className="SplashButton"
            style={{ background: props.rest.hex_color_secondary }}
            title="ORDER DELIVERY"
          />
        </div>

        <img
          src={props.rest.splash.logo}
          className="SplashLogoHorizontal"
          alt="rest_logo"
        />
      </div>
    )
  } else {
    return (
      <div className="SplashContainer">
        {renderHeader()}
        <div className="SplashSubHeader">
          {ReactHtmlParser(props.rest.splash.subheader)}
        </div>

        <CalltoActionButton
          as={hasMultipleLocations(props.rest) ? "button" : "a"}
          onClick={handleOrderClick}
          href={props.rest.ordering_url}
          params={Object.assign({}, props.rest.utms, {
            order_type: "delivery"
          })}
          className="SplashButton"
          style={{ background: props.rest.hex_color_secondary }}
          title="ORDER ONLINE"
        />

        <img
          src="./icons/down.png"
          className="SplashDownArrow"
          onClick={props.scrollToSection}
          alt="arrow-down"
        />
      </div>
    )
  }
}

export default AnalyticsContextHOC(Splash)
