import React, { Component } from "react"
import WebFont from "webfontloader"
import Splash from "./Splash/Splash"
import Location from "./Location/Location"
import Menu from "./Menu/Menu"
import Contact from "./Contact/Contact"
import Header from "./Header/Header"
import { AsyncImg } from "./AsyncImg"
import Announcement from "./Announcement/Announcement"
import AnalyticsContextHOC from "./AnalyticsContext/AnalyticsContextHOC"
import Loading from "./Loading/Loading"
import CustomSection from "./CustomSection/CustomSection"
import Gallery from "./Gallery/Gallery"
import Reviews from "./Reviews/Reviews"
import "./App.css"

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rest: null,
      showAnnouncement: false,
      initialSplashRender: true,
      activeRoute: window.location.hash.slice(1)
    }
    this.isFontConfigured = false
  }

  componentDidMount() {
    const utms = this.getUrlParams()
    fetch("./react_config.json")
      .then(response => response.json())
      .then(data => {
        data["utms"] = utms
        this.props.analytics.setTrackingID(data.ga_id)
        this.setState({ rest: data, showAnnouncement: true }, () => {
          this.setInitialRoute()
        })
      })
  }

  componentDidUpdate(prevState) {
    if (prevState.activeRoute !== this.state.activeRoute) {
      this.scrollToSection(this.state.activeRoute)
    }
  }

  applyPrimaryFont = () => {
    if (this.state.rest.fonts && this.state.rest.fonts.length > 0) {
      let primary_font = this.state.rest.fonts[0].split(":")[0]
      var headers = document.querySelectorAll("h1, h2, h3, h4, h5, h6, button")
      headers.forEach(header => {
        header.style.fontFamily = primary_font
      })
      this.isFontConfigured = true
    }
  }

  setRoute = route => {
    this.setState({ activeRoute: route }, () => {
      window.location.hash = route
    })
  }

  /* return an array of URL Params (used by utms) */
  getUrlParams(url) {
    let params = {}
    let url_path = url

    if (!url_path) {
      url_path = window.location.href
    }
    const parts = url_path.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(
      m,
      key,
      value
    ) {
      params[key] = value
    })
    return params
  }

  /* get a specific URL parameter (used by utm) */
  getUrlParam(p) {
    const param_dict = this.getUrlParams()
    if (param_dict && param_dict[p]) {
      return param_dict[p]
    }
    return null
  }

  setInitialRoute() {
    let path = window.location.pathname.substring(1)
    if (path.length === 0) return
    // check if path is a 301 redirect or else a standard url in sitegen
    if (this.state.rest.redirects) {
      this.state.rest.redirects.forEach(redirect => {
        if (path === redirect.path) {
          //path is setup as a redirect
          window.location.href = redirect.redirect_to
        }
      })
    }
    // handle uppercase paths
    if (path[0] === path[0].toUpperCase()) {
      window.location.href = path.toLowerCase()
    }

    // if not a predefined redirect, scroll to that section
    window.setTimeout(() => {
      this.scrollToSection(path)
    }, 200)
  }

  scrollToSection(s) {
    const supportScroll = "scrollBehavior" in document.documentElement.style
    const element = this[s]
    if (!element) return
    if (supportScroll) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      })
    } else {
      element.scrollIntoView(false)
    }
  }

  closeAnnouncement = () => {
    this.setState({ showAnnouncement: false })
  }

  renderCustomSections() {
    if (this.state.rest.sections) {
      return this.state.rest.sections.map((section, i) => {
        return (
          <section
            key={i}
            ref={sec =>
              (this[section.header.replace(/\s+/g, "-").toLowerCase()] = sec)
            }
          >
            <CustomSection section={section} rest={this.state.rest} />
          </section>
        )
      })
    }
    return
  }

  renderDisclosures() {
    if (this.state.rest.disclosures) {
      return <div className="Disclosures">{this.state.rest.disclosures}</div>
    }
  }

  getBackground() {
    return window.innerWidth < 800 && this.state.rest.splash.background_mobile
      ? this.state.rest.splash.background_mobile
      : this.state.rest.splash.background
  }

  getYear() {
    let dt = new Date()
    return dt.getFullYear()
  }

  render() {
    /*
    Loading default font and all custom fonts
    For docs: https://github.com/typekit/webfontloader
    */
    const WebFontConfig = {
      google: {
        families: ["Nunito Sans:100,200,300,400,500,600,700,800,900"]
      }
    }

    /*
    The `active` field is run when ALL fonts have RENDERED
    Check to see if rest has additional fonts, then add them to configuration
    */
    if (this.state.rest && this.state.rest.fonts) {
      WebFontConfig.active = this.applyPrimaryFont
      WebFontConfig["google"]["families"].push(...this.state.rest.fonts)
    }

    /*
    Since component will rerender, check to see if font has already been configured
    so webfontloader won't rerequest the same fonts
    */
    if (!this.isFontConfigured) {
      WebFont.load(WebFontConfig)
    }

    return this.state.rest ? (
      <div className="App">
        {this.state.rest.announcement && this.state.showAnnouncement && (
          <Announcement
            announcement={this.state.rest.announcement}
            close={this.closeAnnouncement}
          />
        )}
        <Header
          rest={this.state.rest}
          scrollToSection={s => this.scrollToSection(s)}
          setRoute={this.setRoute}
        />
        <AsyncImg
          src={this.getBackground()}
          className="SplashBackground"
          alt="background"
          style={{
            filter: `brightness(${this.state.rest.splash.background_brightness})`
          }}
        />
        <section ref={sec => (this.splash = sec)}>
          <Splash
            rest={this.state.rest}
            scrollToSection={() => this.scrollToSection("location")}
            doNotUpdateRoute={this.state.initialSplashRender}
          />
        </section>
        <div className="PageSlider">
          <section ref={sec => (this.location = sec)}>
            <Location rest={this.state.rest} />
          </section>
          <section ref={sec => (this.gallery = sec)}>
            <Gallery rest={this.state.rest} />
          </section>
          <section ref={sec => (this.reviews = sec)}>
            <Reviews rest={this.state.rest} />
          </section>
          {this.renderCustomSections()}
          <section ref={sec => (this.menu = sec)}>
            <Menu rest={this.state.rest} />
          </section>
          <section ref={sec => (this.contact = sec)}>
            <Contact rest={this.state.rest} />
          </section>
          {this.renderDisclosures()}
          <div id="siteVersion">
            ©{this.getYear()} {this.state.rest.name}
          </div>
        </div>
      </div>
    ) : (
      <Loading />
    )
  }
}

export default AnalyticsContextHOC(App)
