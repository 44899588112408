import React, { useEffect } from "react"
import ReactHtmlParser from "react-html-parser"
import AnalyticsContextHOC from "../AnalyticsContext/AnalyticsContextHOC.js"
import "./Announcement.css"

const Announcement = props => {
  useEffect(() => {
    props.analytics.pageview("Announcement")
  }, [])

  return (
    <div className="AnnouncementBackground" onClick={props.close}>
      <div className="AnnouncementContainer" onClick={e => e.stopPropagation()}>
        <img
          onClick={props.close}
          role="button"
          aria-label="close announcement"
          className="AnnouncementClose"
          src="./icons/close.png"
          alt="close"
        />
        <div className="AnnouncementBody">
          {ReactHtmlParser(props.announcement)}
        </div>
      </div>
    </div>
  )
}

export default AnalyticsContextHOC(Announcement)
