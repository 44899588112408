// custom polyfills
import "react-app-polyfill/ie11"
import "nodelist-foreach-polyfill"
import "intersection-observer"
import React from "react"
import { render } from "react-dom"
import App from "./App"
import * as Sentry from "@sentry/browser"
import AnalyticsProvider from "./AnalyticsContext/AnalyticsContext"

// SENTRY CODE
if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN
  })
}

// IE11 POLYFILLS
// polyfill for IE missing the includes method
// which is required for some stuff (mostly sentry)
// https://mzl.la/2MnpSqD
if (!String.prototype.includes) {
  // eslint-disable-next-line
  String.prototype.includes = function(search, start) {
    // eslint-disable-next-line
    "use strict"
    if (typeof start !== "number") {
      start = 0
    }

    if (start + search.length > this.length) {
      return false
    } else {
      return this.indexOf(search, start) !== -1
    }
  }
}

// polyfill for IE missing the Number.isSafeInteger method
// https://mzl.la/2lGtsPZ
Number.isSafeInteger =
  Number.isSafeInteger ||
  function(value) {
    return Number.isInteger(value) && Math.abs(value) <= Number.MAX_SAFE_INTEGER
  }

// polyfill for IE missing the Number.isInteger method
Number.isInteger =
  Number.isInteger ||
  function(value) {
    return (
      typeof value === "number" &&
      isFinite(value) &&
      Math.floor(value) === value
    )
  }

const rootElement = document.getElementById("root")
render(
  <AnalyticsProvider>
    <App />
  </AnalyticsProvider>,
  rootElement
)
