import React, { Component } from 'react'
import ReactGA from 'react-ga'

export const AnalyticsContext = React.createContext()

class AnalyticsProvider extends Component {

  constructor(props) {
    super(props)

    this.state = {gaTrackingID: null,
      send: (a, b, c) => this.send(a, b, c),
      setTrackingID: (a) => this.setTrackingID(a),
      pageview: (a) => this.pageview(a)}
  }

  setTrackingID = (gaTrackingID) => {
    console.log("setting google analytics = " + gaTrackingID)
    if (gaTrackingID) {
      this.setState({gaTrackingID: gaTrackingID})
      ReactGA.initialize(gaTrackingID)
    }
  }

  send = (category, action, label="") => {
    if (this.state.gaTrackingID) {
      ReactGA.event({
        category: category,
        action: action,
        label: label
      })
    }
  }

  pageview = (url) => {
    if (this.state.gaTrackingID) {
      ReactGA.pageview(url)
    }
  }

  render() {
    return (
      <AnalyticsContext.Provider value={this.state}>
        {this.props.children}
      </AnalyticsContext.Provider>
    )
  }
}

export default AnalyticsProvider
