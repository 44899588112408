import React, { useState } from "react"
import SectionHeader from "../SectionHeader/SectionHeader"
import CalltoActionButton from "../CalltoActionButton/CalltoActionButton"
import { Slider } from "../Slider"
import AnalyticsContextHOC from "../AnalyticsContext/AnalyticsContextHOC.js"
import { LazyImage } from "../LazyImage"
import "./Location.css"

const Location = props => {
  const [addressState, setAddressState] = useState({})
  const [selectedLocation, setSelectedLocation] = useState(0)

  const getLocationFromKey = key => {
    return props.rest.locations[key]
  }

  const setAddress = addresObj => {
    setAddressState({ address: addresObj, addressCenter: true })
    props.analytics.send(props.rest.name, "User Address Entry")
  }

  const selectLocation = ({ currentIndex }) => {
    setSelectedLocation(currentIndex)
    setAddressState({ ...addressState, addressCenter: false })
    props.analytics.send(
      props.rest.name,
      "Location Card Change",
      "Card: " + currentIndex
    )
  }

  const renderRestMarkers = () => {
    return props.rest.locations.map((location, i) => {
      return i === props.rest.locations.length - 1
        ? `${location.lat},${location.lng}`
        : `${location.lat},${location.lng}%7C`
    })
  }

  const renderMap = () => {
    const coords =
      addressState.address && addressState.addressCenter
        ? {
            lat: addressState.address.lat,
            lng: addressState.address.lng
          }
        : {
            lat: parseFloat(
              getLocationFromKey(selectedLocation)["lat"] - 0.001
            ),
            lng: parseFloat(getLocationFromKey(selectedLocation)["lng"] + 0.006)
          }
    const restMarkers = renderRestMarkers()
    const imgSrc = `https://maps.googleapis.com/maps/api/staticmap?center=${
      coords.lat
    },${coords.lng}&zoom=13&size=400x400&maptype=roadmap
    &markers=${restMarkers.join("")}&key=${
      process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    }`
    return (
      <div className="LocationMap">
        <LazyImage src={imgSrc} alt="Map showing restaurant location" />
      </div>
    )
  }

  const handleClick = address => {
    props.analytics.send(
      props.rest.name,
      "Order Online",
      "Location: " + address
    )
  }

  const formatPhone = num => {
    let phone = num
    phone = phone.replace(/-/g, "")
    phone =
      "(" +
      phone.slice(0, 3) +
      ") " +
      phone.slice(3, 6) +
      "-" +
      phone.slice(6, 15)
    return phone
  }

  const renderLocationCards = () => {
    if (props.rest.locations.length === 1) {
      let location = props.rest.locations[0]
      let href_phone = `tel:${location.phone}`
      return (
        <div
          className="LocationCardWrapper"
          style={
            window.innerWidth > 800
              ? {
                  display: "inline-block",
                  width: "40%",
                  verticalAlign: "middle"
                }
              : null
          }
        >
          <div className="LocationCard">
            <img
              className="LocationCardLogo"
              src={props.rest.logo}
              alt="logo"
            />
            <h6 className="LocationCardHeader">{location.name}</h6>
            <div
              className="LocationCardAddress"
              onClick={e => e.stopPropagation()}
            >
              <a
                href={`https://google.com/maps?q=${location.address +
                  " " +
                  location.city +
                  " " +
                  location.state +
                  " " +
                  location.zip}`}
              >
                {location.address}
                <br />
                {location.city}, {location.state} {location.zip}
              </a>
            </div>
            <a href={href_phone}>
              <div>{formatPhone(location.phone)}</div>
            </a>

            <CalltoActionButton
              onClick={() => handleClick(location.address)}
              href={location.order_link}
              params={props.rest.utms}
              className="LocationCardButton"
              style={{ background: props.rest.hex_color_secondary }}
              title="ORDER ONLINE"
            />

            <div className="LocationCardHours">
              <span style={{ fontWeight: 900 }}>HOURS</span>
              <br />
              Mon: {location.hours[1]}
              <br />
              Tues: {location.hours[2]}
              <br />
              Wed: {location.hours[3]}
              <br />
              Thur: {location.hours[4]}
              <br />
              Fri: {location.hours[5]}
              <br />
              Sat: {location.hours[6]}
              <br />
              Sun: {location.hours[0]}
              <br />
            </div>
          </div>
        </div>
      )
    }
    let cards = props.rest.locations.map((location, i) => {
      let href_phone = `tel:${location.phone}`
      return (
        <div key={i} className="LocationCardWrapper">
          <div className="LocationCard">
            <img
              className="LocationCardLogo"
              src={props.rest.logo}
              alt="logo"
            />
            <h6 className="LocationCardHeader">{location.name}</h6>
            <div className="LocationCardAddress">
              <a href={`https://google.com/maps?q=${location.address}`}>
                {location.address}
                <br />
                {location.city}, {location.state} {location.zip}
              </a>
            </div>
            <a href={href_phone}>
              <div>{formatPhone(location.phone)}</div>
            </a>

            <CalltoActionButton
              onClick={() => handleClick(location.address)}
              href={location.order_link}
              params={props.rest.utms}
              className="LocationCardButton"
              style={{ background: props.rest.hex_color_secondary }}
              title="ORDER ONLINE"
            />

            <div className="LocationCardHours">
              <span style={{ fontWeight: 900 }}>HOURS</span>
              <br />
              Mon: {location.hours[1]}
              <br />
              Tues: {location.hours[2]}
              <br />
              Wed: {location.hours[3]}
              <br />
              Thur: {location.hours[4]}
              <br />
              Fri: {location.hours[5]}
              <br />
              Sat: {location.hours[6]}
              <br />
              Sun: {location.hours[0]}
              <br />
            </div>
          </div>
        </div>
      )
    })
    let settings = {
      dots: true,
      startIndex: selectedLocation,
      className: "LocationCardsContainer",
      onSlideChange: current => {
        selectLocation(current)
      }
    }
    const NextArrow = (
      <div className="LocationCardsNext">
        <img src="./icons/next.png" style={{ width: "100%" }} alt="next" />
      </div>
    )

    const PrevArrow = (
      <div className="LocationCardsPrev">
        <img src="./icons/prev.png" style={{ width: "100%" }} alt="previous" />
      </div>
    )
    return (
      <Slider options={settings} nextArrow={NextArrow} prevArrow={PrevArrow}>
        {cards}
      </Slider>
    )
  }

  const renderButtons = () => {
    if (props.rest.locations.length > 3) {
      return (
        <div className="LocationButtons">
          {props.rest.locations.map((location, i) => (
            <button
              style={{
                background:
                  selectedLocation === i
                    ? props.rest.hex_color_primary
                    : props.rest.hex_color_secondary
              }}
              onClick={() => setSelectedLocation(i)}
              key={i}
              className="LocationListButton"
            >
              {location.name}
            </button>
          ))}
        </div>
      )
    }
  }

  const renderSelectedLocation = () => {
    let location = props.rest.locations[selectedLocation]
    let href_phone = `tel:${location.phone}`
    let fullAddress =
      location.name +
      ", " +
      location.address +
      ", " +
      location.city +
      ", " +
      location.state +
      " " +
      location.zip
    let addressURL = encodeURIComponent(fullAddress.trim())
    return (
      <div className="LocationSelected">
        <div className="LocationSelectedText">
          <h6 className="LocationSelectedHeader">{location.name}</h6>

          <CalltoActionButton
            onClick={() => handleClick(location.address)}
            href={location.order_link}
            params={props.rest.utms}
            className="LocationCardButton"
            style={{ background: props.rest.hex_color_secondary }}
            title="ORDER NOW"
          />

          <a href={href_phone}>
            <div className="LocationPhone">{formatPhone(location.phone)}</div>
          </a>
          <div className="LocationCardAddress">
            {location.address}
            <br />
            {location.city}, {location.state} {location.zip}
          </div>
          <a
            href={`https://maps.google.com/maps/dir/?api=1&destination=${addressURL}`}
            target="_blank"
            rel="noopener"
          >
            <div className="LocationDirections">Get directions</div>
          </a>
        </div>
        <div className="LocationSelectedText">
          <div className="LocationListHours">
            <span style={{ fontWeight: 900 }}>HOURS</span>
            <br />
            Mon: {location.hours[1]}
            <br />
            Tues: {location.hours[2]}
            <br />
            Wed: {location.hours[3]}
            <br />
            Thur: {location.hours[4]}
            <br />
            Fri: {location.hours[5]}
            <br />
            Sat: {location.hours[6]}
            <br />
            Sun: {location.hours[0]}
            <br />
          </div>
        </div>
      </div>
    )
  }

  const renderLocationList = () => {
    return props.rest.locations.map((location, i) => {
      let href_phone = `tel:${location.phone}`
      let fullAddress =
        location.name +
        ", " +
        location.address +
        ", " +
        location.city +
        ", " +
        location.state +
        " " +
        location.zip
      let addressURL = encodeURIComponent(fullAddress.trim())
      return (
        <div className="LocationListElement">
          <div className="LocationSelectedText">
            <h6 className="LocationSelectedHeader">{location.name}</h6>

            <CalltoActionButton
              onClick={() => handleClick(location.address)}
              href={location.order_link}
              params={props.rest.utms}
              className="LocationCardButton"
              style={{ background: props.rest.hex_color_secondary }}
              title="ORDER NOW"
            />

            <a href={href_phone}>
              <div className="LocationPhone">{formatPhone(location.phone)}</div>
            </a>
            <div className="LocationCardAddress">
              {location.address}
              <br />
              {location.city}, {location.state} {location.zip}
            </div>
            <a
              href={`https://maps.google.com/maps/dir/?api=1&destination=${addressURL}`}
              target="_blank"
              rel="noopener"
            >
              <div className="LocationDirections">Get directions</div>
            </a>
          </div>
          <div className="LocationSelectedText">
            <div className="LocationListHours">
              <span style={{ fontWeight: 900 }}>HOURS</span>
              <br />
              Mon: {location.hours[1]}
              <br />
              Tues: {location.hours[2]}
              <br />
              Wed: {location.hours[3]}
              <br />
              Thur: {location.hours[4]}
              <br />
              Fri: {location.hours[5]}
              <br />
              Sat: {location.hours[6]}
              <br />
              Sun: {location.hours[0]}
              <br />
            </div>
          </div>
        </div>
      )
    })
  }

  const renderContent = () => {
    if (
      props.rest.location_type &&
      props.rest.location_type === "LIST" &&
      props.rest.locations.length > 3
    ) {
      return (
        <div
          className="LocationList"
          style={{ justifyContent: "space-around", alignItems: "center" }}
        >
          {renderButtons()}
          {renderSelectedLocation()}
        </div>
      )
    }
    if (props.rest.location_type && props.rest.location_type === "LIST") {
      return <div className="LocationList">{renderLocationList()}</div>
    } else {
      return (
        <div className="location__container">
          {renderMap()}
          {renderLocationCards()}
        </div>
      )
    }
  }

  let title =
    props.rest.locations.length === 1 ? "Address & Hours" : "Locations"
  return (
    <div className="LocationContainer">
      <SectionHeader title={title} rest={props.rest} />
      {renderContent()}
    </div>
  )
}

export default AnalyticsContextHOC(Location)
