import React from "react"
import SectionHeader from "../SectionHeader/SectionHeader"
import { Slider } from "../Slider"
import { LazyImage } from "../LazyImage"
import AnalyticsContextHOC from "../AnalyticsContext/AnalyticsContextHOC.js"

import "./Reviews.css"

const Reviews = props => {
  const handleClick = review => {
    props.analytics.send(
      props.rest.name,
      review.type + "Review Link",
      review.name + "'s Review"
    )
  }

  const renderReviews = () => {
    let cards = props.rest.reviews.map((review, i) => {
      let img_url =
        review.type === "google" ? "./icons/google.jpg" : "./icons/yelp.jpeg"
      let button_style =
        review.type === "google"
          ? { background: "#4284f4" }
          : { background: "#a51014" }
      return (
        <div key={i} className="ReviewWrapper">
          <div className="ReviewCard">
            <LazyImage className="ReviewCardLogo" src={img_url} alt="logo" />
            <div className="ReviewCardHeader">{review.name}</div>
            <div className="ReviewCardBody">"{review.body}"</div>
            <a href={review.url} target="_blank" rel="noopener noreferrer">
              <button
                className="ReviewCardButton"
                style={button_style}
                onClick={handleClick}
              >
                VIEW ON {review.type}
              </button>
            </a>
          </div>
        </div>
      )
    })
    let settings = {
      dots: true,
      className: "ReviewsCardContainer",
      style: {
        maxWidth: 500,
        minHeight: 500
      }
    }

    const NextArrow = (
      <div className="ReviewsCardsNext">
        <img src="./icons/next.png" style={{ width: "100%" }} alt="next" />
      </div>
    )
    const PrevArrow = (
      <div className="ReviewsCardsPrev">
        <img src="./icons/prev.png" style={{ width: "100%" }} alt="previous" />
      </div>
    )
    return (
      <Slider nextArrow={NextArrow} prevArrow={PrevArrow} options={settings}>
        {cards}
      </Slider>
    )
  }

  if (props.rest.reviews && props.rest.reviews.length > 0) {
    return (
      <div className="ReviewsContainer">
        <SectionHeader title="Reviews" rest={props.rest} />
        {renderReviews()}
      </div>
    )
  } else return null
}

export default AnalyticsContextHOC(Reviews)
