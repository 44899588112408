import React from "react"
import URLFormatter from "../helpers/URLFormatter"
import "./CallToActionButton.css"

const CalltoActionButton = props => {
  /* this component may be receiving some UTM or
     other parameters in the form of the params 
     input. if so, then structure the outgoing URL */
  const formatURL = () => {
    return URLFormatter(props.href, props.params)
  }

  const elProps = {
    a: {
      target: "_blank",
      href: formatURL()
    },
    button: {}
  }
  const WrapperElement = props.as
  return (
    <WrapperElement
      onClick={props.onClick}
      role="button"
      className={`${props.className} callToActionButton`}
      style={props.style}
      {...elProps[props.as]}
    >
      {props.title}
    </WrapperElement>
  )
}

CalltoActionButton.defaultProps = {
  as: "a",
  href: window.location.pathname,
  onClick: () => {}
}

export default CalltoActionButton
