import React from "react"
import SectionHeader from "../SectionHeader/SectionHeader"
import "./Menu.css"

const Menu = props => {
  const renderMenuBody = () => {
    let categories = []
    let html_menu = props.rest.html_menu

    if (html_menu === null) {
      html_menu = {}
    }

    let cat_arr = Object.keys(html_menu).map(key => {
      return [key, html_menu[key]]
    })
    cat_arr.sort((first, second) => {
      return second[1].length - first[1].length
    })
    let sorted_categories = {}
    for (var cat in cat_arr) {
      sorted_categories[cat_arr[cat][0]] = cat_arr[cat][1]
    }

    for (var category in sorted_categories) {
      categories.push(
        <div key={categories.length} className="MenuCategoryContainer">
          <h4
            className="MenuCategory"
            style={{ color: props.rest.hex_color_secondary }}
          >
            {category}
          </h4>
          {sorted_categories[category].map((item, i) => {
            return (
              <div key={i} className="MenuItem">
                {item.name}
                <span
                  style={{ color: props.rest.hex_color_primary }}
                  className="MenuItemPrice"
                >
                  {item.price.substring(2)}
                </span>
              </div>
            )
          })}
        </div>
      )
    }
    return categories
  }

  return (
    <div className="MenuContainer">
      <SectionHeader title="Menu" rest={props.rest} />
      {props.rest.menu_header && (
        <p style={{ margin: "12px 24px", fontWeight: 900 }}>
          {props.rest.menu_header}
        </p>
      )}
      {renderMenuBody()}
    </div>
  )
}

export default Menu
