import React, { useEffect, useRef, useState } from "react"
import "./LazyImage.css"

const LazyImage = props => {
  const [imgSrc, setImgSrc] = useState(
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII="
  )
  const targetRef = useRef(null)
  useEffect(() => {
    let observer
    let didCancel = false

    // set target inside effect so that cleanup function has a reference to it
    const target = targetRef.current

    if (IntersectionObserver && targetRef && targetRef.current) {
      if (IntersectionObserver) {
        observer = new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              // when image is visible in the viewport + rootMargin
              if (
                !didCancel &&
                (entry.intersectionRatio > 0 || entry.isIntersecting)
              ) {
                setImgSrc(props.src)
              }
            })
          },
          {
            threshold: 0.01,
            rootMargin: "75%"
          }
        )
        observer.observe(targetRef.current)
      } else {
        // Old browsers fallback
        setImgSrc(props.src)
      }
    }
    return () => {
      didCancel = true
      // on component unmount, we remove the listner
      if (observer && observer.unobserve) {
        observer.unobserve(target)
      }
    }
  })

  return <img src={imgSrc} alt={props.alt} {...props} ref={targetRef} />
}

export default LazyImage
