import React, { useEffect } from "react"
import SectionHeader from "../SectionHeader/SectionHeader"
import ReactHtmlParser from "react-html-parser"
import { AsyncImg } from "../AsyncImg"
import { LazyImage } from "../LazyImage"
import "./CustomSection.css"
import AnalyticsContextHOC from "../AnalyticsContext/AnalyticsContextHOC.js"

const CustomSection = props => {
  useEffect(() => {
    props.analytics.pageview(props.section.header)
  }, [])

  const addDefaultSrc = e => {
    e.target.src = props.rest.logo
  }

  return (
    <div className="CustomSectionContainer">
      <SectionHeader title={props.section.header} rest={props.rest} />
      <AsyncImg
        className="CustomSectionImg"
        src={props.section.img}
        onError={e => addDefaultSrc(e)}
        lazy
        alt="custom-img"
      />
      <div className="CustomSectionBody">
        {ReactHtmlParser(props.section.body)}
      </div>
    </div>
  )
}

export default AnalyticsContextHOC(CustomSection)
