const URLFormatter = function(hrefpath, params={}) {
  let path = hrefpath.substring(hrefpath.indexOf('?')+1)

  if (hrefpath.indexOf('?') > 0) {
    path = hrefpath.substring(0, hrefpath.indexOf('?'))
  }

  // look and see if we have an interesting referrer
  // preferably a different domain than we currently
  // sit on (ie yelp, etc) -- only do this if we dont
  // already have a utm set (aka params is empty)
  if (document.referrer) {
    const referrer_domain = (new URL(document.referrer)).hostname
    const local_domain = (new URL(document.location)).hostname
    if (referrer_domain !== local_domain 
      && !params['utm_medium'] && !params['utm_source'] ) {
      params['utm_medium'] = 'referrer'
      params['utm_source'] = referrer_domain
    } 
  }

  // if no params, dont do any more work
  if (Object.keys(params).length === 0) {
    return path
  }

  // hbpath is the hashbang paths like index.php#fizzbuzz
  let hbpath = ""
  if (hrefpath.indexOf('#') > 0) {
    hbpath = hrefpath.substring(hrefpath.indexOf('#'))
  }

  // encode the params as data and then url encode them 
  let data = Object.entries(params)
  data = data.map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
  let query = data.join('&');
  return path + "?" + query + hbpath
}

export default URLFormatter;
