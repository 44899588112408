import React, { useState } from "react"
import SectionHeader from "../SectionHeader/SectionHeader"
import { Slider } from "../Slider"
import { AsyncImg } from "../AsyncImg"
import Modal from "../Modal/Modal"
import "./Gallery.css"

const Gallery = props => {
  const [imageState, setImageState] = useState({})

  const renderExpansion = () => {
    if (imageState.expanded) {
      return (
        <Modal
          close={() => setImageState({ expanded: null, expanded_text: null })}
        >
          <AsyncImg
            className="gallery-modal-image"
            src={imageState.expanded}
            alt={imageState.expanded_text}
          />
          <h3 style={{ textAlign: "center" }}>{imageState.expanded_text}</h3>
        </Modal>
      )
    }
  }

  const renderGallery = () => {
    let cards = Object.keys(props.rest.gallery).map((img_key, i) => {
      let pic = props.rest.gallery[img_key]
      return (
        <AsyncImg
          key={i}
          className="gallery-img"
          onClick={() =>
            setImageState({ expanded: pic, expanded_text: img_key })
          }
          lazy
          src={pic}
          alt={img_key}
        />
      )
    })
    let settings = {
      dots: true,
      className: "GalleryCardContainer",
      innerClassName: "gallery-cary-slider__inner",
      style: {
        width: window.innerWidth > 800 ? 500 : 300,
        minHeight: window.innerWidth > 800 ? 400 : 200
      }
    }
    const NextArrow = (
      <div className="GalleryCardsNext">
        <img
          src="./icons/next.png"
          style={{ width: "100%" }}
          alt="next"
          loading="lazy"
        />
      </div>
    )
    const PrevArrow = (
      <div className="GalleryCardsPrev">
        <img
          src="./icons/prev.png"
          style={{ width: "100%" }}
          alt="previous"
          loading="lazy"
        />
      </div>
    )
    return (
      <Slider options={settings} nextArrow={NextArrow} prevArrow={PrevArrow}>
        {cards}
      </Slider>
    )
  }

  if (Object.keys(props.rest.gallery).length > 0) {
    return (
      <div className="GalleryContainer">
        <SectionHeader title="Gallery" rest={props.rest} />
        {renderGallery()}
        {renderExpansion()}
      </div>
    )
  } else return null
}

export default Gallery
