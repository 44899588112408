import React from "react"
import SectionHeader from "../SectionHeader/SectionHeader"
import AnalyticsContextHOC from "../AnalyticsContext/AnalyticsContextHOC.js"
import "./Contact.css"

const Contact = props => {
  const handleSubmit = () => {
    props.analytics.send(props.rest.name, "Contact Form Submit")
  }

  if (props.rest.contact) {
    let contact_url = `//formspree.io/${props.rest.contact}`
    return (
      <div className="ContactContainer">
        <SectionHeader title="CONTACT US" rest={props.rest} />
        <img src={props.rest.logo} className="ContactImg" alt="contact-img" />
        <div className="ContactBody">
          <div>
            Reach out to learn more about us! Questions about our food, our
            location, or anything else? Contact us here:
          </div>
          <br />
          <form
            action={contact_url}
            method="POST"
            className="ContactFormContent"
          >
            <label className="ContactLabel" htmlFor="name">
              Name
            </label>
            <input
              className="ContactInput"
              type="text"
              placeholder="Your name..."
              name="name"
            />
            <input type="hidden" name="_cc" value="formspree@hngr.co" />
            <label className="ContactLabel" htmlFor="_replyto">
              Email
            </label>
            <input
              className="ContactInput"
              type="email"
              placeholder="Your email..."
              name="_replyto"
            />
            <label className="ContactLabel" htmlFor="message">
              Message
            </label>
            <textarea
              className="ContactInput"
              rows="3"
              placeholder="Your message..."
              name="message"
            />
            <button
              className="ContactButton"
              style={{ background: props.rest.hex_color_primary }}
              type="submit"
              onClick={handleSubmit}
            >
              SUBMIT
            </button>
          </form>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default AnalyticsContextHOC(Contact)
