import React, { useState, useEffect } from "react"
import classnames from "classnames"
import PropTypes from "prop-types"

import "./Slider.css"

const Slider = props => {
  // Set the current viewable element to the props.startIndex (defaults to 0)
  const [current, setCurrent] = useState({ index: props.startIndex })

  /*
    Event handlers
  */

  useEffect(() => {
    if (props.options.onSlideChange) {
      props.options.onSlideChange({
        currentIndex: current.index,
        currentElement: props.children[current.index]
      })
    }
  }, [current.index]) //eslint-disable-line

  const handleNext = () => {
    if (current.index === props.children.length - 1) {
      setCurrent({ index: 0 })
      return
    }

    setCurrent({ index: current.index + 1 })
  }

  const handlePrev = () => {
    if (current.index === 0) {
      setCurrent({ index: props.children.length - 1 })
      return
    }

    setCurrent({ index: current.index - 1 })
  }

  const handleBottomClick = key => {
    // Check to ensure that key press is a valid index of children
    if (key < 0 || key >= props.children.length) {
      return
    }
    setCurrent({ index: key })
  }

  /*
  Add event listeners to passed components here by:
  1. cloning element
  2. adding prop
  */

  const nextArrowWithCLick = React.cloneElement(props.nextArrow, {
    className: classnames(
      props.nextArrow.props.className,
      "slider__arrow--right"
    ),
    role: "button",
    "aria-label": "Next",
    onClick: handleNext
  })
  const prevArrowWithClick = React.cloneElement(props.prevArrow, {
    className: classnames(
      props.prevArrow.props.className,
      "slider__arrow--left"
    ),
    role: "button",
    "aria-label": "Previous",
    onClick: handlePrev
  })

  const Children = props.children.map((child, i) => {
    return React.cloneElement(child, {
      key: i
    })
  })

  /*
    Render the buttons at the bottom based on # of children
  */

  const buttons = props.children.map((child, i) => {
    return (
      <button
        className={classnames("slider__button", {
          "slider__button--active": current.index === i
        })}
        key={i}
        name={`Slide ${i}`}
        aria-label={`Go to slide ${i + 1}`}
        onClick={() => handleBottomClick(i)}
      />
    )
  })

  return (
    <div
      className={classnames("slider__container", {
        [props.options.className]: props.options.className
      })}
    >
      <div
        className={classnames("slider__inner-container", {
          [props.options.innerClassName]: props.options.innerClassName
        })}
      >
        {prevArrowWithClick}

        <div className="slider__content-container" style={props.options.style}>
          {Children[current.index]}
        </div>
        {nextArrowWithCLick}
      </div>
      {props.options.dots && (
        <div className="slider__dots-container">{buttons}</div>
      )}
    </div>
  )
}

Slider.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  nextArrow: PropTypes.element,
  prevArrow: PropTypes.element,
  options: PropTypes.shape({
    dots: PropTypes.bool,
    style: PropTypes.shape({
      maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    onSlideChange: PropTypes.func,
    className: PropTypes.string,
    innerClassName: PropTypes.string
  })
}

Slider.defaultProps = {
  startIndex: 0,
  nextArrow: <button>next</button>,
  prevArrow: <button>prev</button>,
  options: { onSlideChange: () => {} }
}

export default Slider
